import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
import { graphql } from 'gatsby';
import Callout from '../../components/Callout';
import CodeBlock from '../../components/CodeBlock';
import ComponentApi from '../../components/ComponentApi';
import ReactPlayground from '../../components/ReactPlayground';
import NavAlignement from '../../examples/Nav/Alignment';
import NavBasic from '../../examples/Nav/Basic';
import NavList from '../../examples/Nav/List';
import NavDropdown from '../../examples/Nav/Dropdown';
import NavDropdownImpl from '../../examples/Nav/DropdownImpl';
import NavFill from '../../examples/Nav/Fill';
import NavJustified from '../../examples/Nav/Justified';
import NavStacked from '../../examples/Nav/Stacked';
import Tabs from '../../examples/Nav/Tabs';
import Pills from '../../examples/Nav/Pills';
export const query = graphql`
  query NavQuery {
    Nav: componentMetadata(displayName: { eq: "Nav" }) {
      displayName
      ...ComponentApi_metadata
    }
    NavItem: componentMetadata(displayName: { eq: "NavItem" }) {
      displayName
      ...ComponentApi_metadata
    }
    NavLink: componentMetadata(displayName: { eq: "NavLink" }) {
      displayName
      ...ComponentApi_metadata
    }
    NavDropdown: componentMetadata(displayName: { eq: "NavDropdown" }) {
      displayName
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "base-nav"
    }}>{`Base Nav`}</h1>
    <p>{`Navigation bits in Bootstrap all share a general `}<inlineCode parentName="p">{`Nav`}</inlineCode>{`
component and styles. Swap `}<inlineCode parentName="p">{`variant`}</inlineCode>{`s to switch between each
style. The base `}<inlineCode parentName="p">{`Nav`}</inlineCode>{` component is built with flexbox and
provide a strong foundation for building all types of navigation
components.`}</p>
    <Callout theme="warning" mdxType="Callout">
  The basic, variant-less, <code>Nav</code> component does not include any{' '}
  <code>active</code> prop styling!
    </Callout>
    <ReactPlayground codeText={NavBasic} mdxType="ReactPlayground" />
    <p><inlineCode parentName="p">{`<Nav>`}</inlineCode>{` markup is very flexible and styling is controlled via classes so you can
use whatever elements you like to build your navs. By default `}<inlineCode parentName="p">{`<Nav>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<Nav.Item>`}</inlineCode>{` both
render `}<inlineCode parentName="p">{`<div>`}</inlineCode>{`s instead of `}<inlineCode parentName="p">{`<ul>`}</inlineCode>{` and `}<inlineCode parentName="p">{`<li>`}</inlineCode>{` elements respectively.
This because it's possible (and common) to leave off the `}<inlineCode parentName="p">{`<Nav.Item>`}</inlineCode>{`'s and
render a `}<inlineCode parentName="p">{`<Nav.Link>`}</inlineCode>{` directly, which would create invalid markup by default (`}<inlineCode parentName="p">{`ul > a`}</inlineCode>{`).`}</p>
    <p>{`When a `}<inlineCode parentName="p">{`<ul>`}</inlineCode>{` is appropriate you can render one via the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop; be sure to
also set your items to `}<inlineCode parentName="p">{`<li>`}</inlineCode>{` as well!`}</p>
    <ReactPlayground codeText={NavList} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "alignment-and-orientation"
    }}>{`Alignment and orientation`}</h2>
    <p>{`You can control the the direction and orientation of the
`}<inlineCode parentName="p">{`Nav`}</inlineCode>{` by making use of the `}<a parentName="p" {...{
        "href": "https://getbootstrap.com/docs/4.0/layout/grid/#horizontal-alignment"
      }}>{`flexbox layout`}</a>{` utility classes.
By default, navs are left-aligned, but that is easily changed to center or right-aligned.`}</p>
    <ReactPlayground codeText={NavAlignement} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "vertical"
    }}>{`Vertical`}</h3>
    <p>{`Create stacked navs by changing the flex item direction with the
`}<inlineCode parentName="p">{`.flex-column`}</inlineCode>{` class, or your own css. You can even use the
responsive versions to stack in some viewports but not others (e.g.
`}<inlineCode parentName="p">{`.flex-sm-column`}</inlineCode>{`).`}</p>
    <ReactPlayground codeText={NavStacked} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "tabs"
    }}>{`Tabs`}</h2>
    <p>{`Visually represent nav items as "tabs". This style pairs nicely with
tabbable regions created by our `}<a parentName="p" {...{
        "href": "../tabs/"
      }}>{`Tab components`}</a>{`.`}</p>
    <p>{`Note: creating a vertical nav (`}<inlineCode parentName="p">{`.flex-column`}</inlineCode>{`) with tabs styling is unsupported by Bootstrap's default stylesheet.`}</p>
    <ReactPlayground codeText={Tabs} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "pills"
    }}>{`Pills`}</h2>
    <p>{`An alternative visual variant.`}</p>
    <ReactPlayground codeText={Pills} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "fill-and-justify"
    }}>{`Fill and justify`}</h2>
    <p>{`Force the contents of your nav to extend the full available width. To
proportionately fill the space use `}<inlineCode parentName="p">{`fill`}</inlineCode>{`. Notice that the
nav is the entire width but each nav item is a different size.`}</p>
    <ReactPlayground codeText={NavFill} mdxType="ReactPlayground" />
    <p>{`If you want each NavItem to be the same size use `}<inlineCode parentName="p">{`justify`}</inlineCode>{`.`}</p>
    <ReactPlayground codeText={NavJustified} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "using-dropdowns"
    }}>{`Using dropdowns`}</h2>
    <p>{`You can mix and match the Dropdown components with the NavLink and
NavItem components to create a Dropdown that plays well in a Nav
component`}</p>
    <CodeBlock codeText={NavDropdownImpl} mdxType="CodeBlock" />
    <p>{`The above demonstrates how flexible the component model can be. But if
you didn't want to roll your own versions we've included a
straight-forward `}<inlineCode parentName="p">{`<NavDropdown>`}</inlineCode>{` that works for most cases.`}</p>
    <ReactPlayground codeText={NavDropdown} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ComponentApi metadata={props.data.Nav} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.NavItem} exportedBy={props.data.Nav} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.NavLink} exportedBy={props.data.Nav} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.NavDropdown} mdxType="ComponentApi" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      